import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import resources from './resources';
import { timeSince } from '../utils/index'

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['en', 'de', 'es', "tr"],
    fallbackLng: ["en"],
    debug: true, // Hata ayıklama modu

    resources,
    ns: ['common'],
    defaultNS: 'common',

    interpolation: {
      escapeValue: false,
      format: (value, format, lng) => {
        if (value instanceof Date) {
          if (format === "ago") {
            return timeSince(value);
          }
        }
        return value.toString();
      }
    },

    // Dil Algılama Seçenekleri
    // detection: {
    //   order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag'],
    //   caches: ['localStorage', 'cookie'],
    //   // Daha fazla seçenek ve ayar için i18next-browser-languagedetector dokümantasyonuna bakabilirsiniz.
    // },
  });

export default i18n;
