
const initialState = {
    loading:false,
    success: false,
    error: false
}

export default function (state= initialState, action) {
    switch (action.type){
        case 'FORGOTPASSWORDVALIDAIDHASH_REQUEST': {
            return {...initialState, loading: true};
        }
        case 'FORGOTPASSWORDVALIDAIDHASH_SUCCESS': {
            return {...state, success: true, error: false,  loading: false}
        }
        case 'FORGOTPASSWORDVALIDAIDHASH_FAILURE': {
            return {...state, success: false, error: true, loading: false}
        }
            default:
                return state;
    }
}