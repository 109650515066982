import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { PrivateRoute } from "./PrivateRoute/PrivateRoute";
import { getListRequest } from "../../../redux/actions/base/getListRequest";
import { useDispatch } from "react-redux";
import { getRequest } from "../../../redux/actions/base/getRequestAction";
import { useLocation } from "react-router-dom";
import { getAccessToken } from "../../../utils/helpers/tokenHelper";

const AuthRoutes = React.lazy(() => import("./AuthRoutes"));
const WrappedRoutes = React.lazy(() => import("./WrappedRoutes"));
const Login = React.lazy(() => import("./AuthRoutes/Login"));
const Register = React.lazy(() => import("./AuthRoutes/Register"));
const About = React.lazy(() => import("./About"));
const PrivacyPolicy = React.lazy(() => import("./PrivacyPolicy"));
const TermsOfService = React.lazy(() => import("./TermsOfService"));
const RefundPolicy = React.lazy(() => import("./RefundPolicy"));

const Router = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    // dispatch(getListRequest("helpers/instagram-accounts"));
    if (
      getAccessToken() &&
      location.pathname != "/login" &&
      location.pathname != "/signup" &&
      location.pathname != "/about" &&
      location.pathname != "/privacy-policy" &&
      location.pathname != "/terms-of-service" &&
      location.pathname != "/refund-policy"
    ) {
      dispatch(getRequest("instagram/users"));
      dispatch(getListRequest("integration/ecommerce/categories"));
      dispatch(getListRequest("helpers/users"));
    }
  }, []);
  return (
    <Switch>
      <Route path="/auth" component={AuthRoutes} />
      <Route path="/login" component={Login} />
      <Route path="/signup" component={Register} />
      <Route path="/about" component={About} />
      <Route path="/privacy-policy" component={PrivacyPolicy} />
      <Route path="/terms-of-service" component={TermsOfService} />
      <Route path="/refund-policy" component={RefundPolicy} />
      <PrivateRoute path="/" component={WrappedRoutes} />
    </Switch>
  );
};

export default Router;
