
const initialState = {
  data: {
    data: [],
    loading: false,
    page: 0,
    pageSize: 25,
    totalCount: 0,
  },
  error: null,
  selectedRows: [],
  payload: {
  }

};

export const queryPageRequest = (namespace) => (state = initialState, action) => {
  switch (action.type) {
    case `${namespace}/QUERY_PAGE_REQUEST_REQUEST`: {
      return { ...state, data: { ...state.data, loading: true }, error: null };
    }
    case `${namespace}/QUERY_PAGE_REQUEST_SUCCESS`: {
      return { ...state, data: { ...state.data, data: action.data?.data, page: action.data?.meta?.current_page - 1, pageSize: action.data?.meta?.per_page, totalCount: action.data?.meta?.total, loading: false } };
    }
    case `${namespace}/QUERY_PAGE_REQUEST_FAILURE`: {
      return { ...state, data: { ...state.data, data: [], page: 0, pageSize: state?.pageSize, totalCount: 0, loading: false }, error: action.error };
    }
    case `${namespace}/QUERY_PAGE_REQUEST_SET_PAGE`: {
      return { ...state, data: { ...state.data, page: action.data?.page } }
    }
    case `${namespace}/QUERY_PAGE_REQUEST_RESET_PAGE`: {
      return { ...state, data: { ...state.data, page: 0 } }
    }
    case `${namespace}/QUERY_PAGE_REQUEST_SET_PAGE_SIZE`: {
      return { ...state, data: { ...state.data, page: 0, pageSize: state.data?.pageSize } }
    }
    case `${namespace}/QUERY_PAGE_REQUEST_RESET_PAGE_SIZE`: {
      return { ...state, data: { ...state.data, pageSize: 10 } }
    }
    case `${namespace}/QUERY_PAGE_REQUEST_SET_PAGE_AND_PAGE_SIZE`: {
      return { ...state, data: { ...state.data, page: action.data?.page, pageSize: action?.data?.pageSize } }
    }
    case `${namespace}/QUERY_PAGE_REQUEST_SET_PAYLOAD`: {
      return { ...state, data: { ...state.data, page: 0, pageSize: state.data?.pageSize }, payload: action.data };
    }
    case `${namespace}/QUERY_PAGE_REQUEST_RESET_PAYLOAD`: {
      return { ...state, data: { ...state.data, page: 0, data: [], pageSize: 10}, payload: { ...initialState.payload } };
    }
    case `${namespace}/QUERY_PAGE_REQUEST_RESET`: {
      return initialState;
    }
    default:
      return state;
  }

};
