const initialState = {
    success: false,
    error: false,
    data: []
}

export default function (state= initialState, action) {
    switch (action.type){
        case 'SIGNUP_REQUEST': {
            return {...initialState, loading: true};
        }
        case 'SIGNUP_SUCCESS': {
            return {...state, data: action.data, success: true, error: false,  loading: false}
        }
        case 'SIGNUP_FAILURE': {
            return {...state ,data: [], success: false, error: true, loading: false}
        }
            default:
                return state;
    }
}