import i18n from '../translations/index';

export const scrollNavigation = () => {
  var doc = document.documentElement;
  var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
  var topnav = document.getElementById('topnav');
  if (top > 60 && topnav) {
    topnav.classList.add('nav-sticky');
  }
  else if (topnav) {
    topnav.classList.remove('nav-sticky');
  }
}


export const niceBytes = (x) => {
  const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  let l = 0, n = parseInt(x, 10) || 0;

  while (n >= 1024 && ++l) {
    n = n / 1024;
  }

  return (n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
}


const intervals = [
  { label: 'year', seconds: 31536000 },
  { label: 'month', seconds: 2592000 },
  { label: 'day', seconds: 86400 },
  { label: 'hour', seconds: 3600 },
  { label: 'minute', seconds: 60 },
  { label: 'second', seconds: 1 }
];

export function timeSince(date) {
  const t = i18n.t;
  const seconds = Math.floor((Date.now() - date.getTime()) / 1000);

  if (seconds) {
    const interval = intervals.find(i => i.seconds < seconds);
    const count = Math.floor(seconds / interval.seconds);
    return `${count} ${t(`time_interval.${interval.label}${count !== 1 ? 's' : ''}`)}`;
  } else {
    return ""
  }

  return "";
}

export var groupBy = function (xs, key) {
  return xs?.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};


const _MS_PER_DAY = 1000 * 60 * 60 * 24;
export function dateDiffInDays(a, b) {
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}


export function toDate(dateString, format) {
  var normalized = dateString?.replace(/[^a-zA-Z0-9]/g, '-');
  var normalizedFormat = format?.toLowerCase().replace(/[^a-zA-Z0-9]/g, '-');
  var formatItems = normalizedFormat?.split('-');
  var dateItems = normalized?.split('-');

  var monthIndex = formatItems?.indexOf("mm");
  var dayIndex = formatItems?.indexOf("dd");
  var yearIndex = formatItems?.indexOf("yyyy");
  var hourIndex = formatItems?.indexOf("hh");
  var minutesIndex = formatItems?.indexOf("ii");
  var secondsIndex = formatItems?.indexOf("ss");

  var today = new Date();

  var year = yearIndex > -1 ? dateItems[yearIndex] : today.getFullYear();
  var month = monthIndex > -1 ? dateItems[monthIndex] - 1 : today.getMonth() - 1;
  var day = dayIndex > -1 ? dateItems[dayIndex] : today.getDate();

  var hour = hourIndex > -1 ? dateItems[hourIndex] : today.getHours();
  var minute = minutesIndex > -1 ? dateItems[minutesIndex] : today.getMinutes();
  var second = secondsIndex > -1 ? dateItems[secondsIndex] : today.getSeconds();

  return new Date(year, month, day, hour, minute, second);
};