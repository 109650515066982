import { show } from "react-notification-system-redux";
import {t} from "i18next";
import system_user from "../../../assets/images/user/system_user.png";
import React, { useEffect, useState } from "react";

export function addNotification(
  { messageText, level },
  position = "br",
  autoDismiss = 5
) {


  const message = (
    <React.Fragment>
      <div className="d-flex align-items-center">
        <div style={{ paddingRight: "1rem" }}>
          {/* <img
            id="topbarRight-image"
            src={system_user}
            className="avatar avatar-sm rounded-circle"

          /> */}
          {level === "success" ? (
            <i className="fas fa-check-circle notification-success-icon"></i>
          ) : (
            <i className="fa-solid fa-circle-exclamation notification-error-icon"></i>
          )}
        </div>
        <span className="font-size-14 font-weight-500">
          {" "}
          {t(`message:${messageText}`)}{" "}
        </span>
      </div>

      <div className="square">
        <div className="left"></div>
        <div className="center"></div>
        <div className="right"></div>
      </div>
    </React.Fragment>
  );
  const notificationOpts = {
    message: message,
    position: position,
    autoDismiss: autoDismiss,
  };

  return function (dispatch) {
    dispatch(show(notificationOpts, level));
  };
}

// i18next.t(`message:${messageText}`);
