const initialState = {
  loading: false,
  success: false,
  error: false,
  data: []
};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'LOGIN_REQUEST': {
      return {...initialState, loading: true, error: false};
    }
    case 'LOGIN_SUCCESS': {
      return {...state, success: true, loading: false, error: false};
    }
    case 'LOGIN_FAILURE': {
      return {...state, data: action.data, error: true, loading: false};
    }
    case 'LOGOUT' : {
      // return  {...state};
      return  initialState;
    }
    default:
      return state;
  }
}
