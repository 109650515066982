
const initialState = {
  data: {
    data: [],
    loading: false,
    page: 0,
    pageSize: 10,
    totalCount: 0
  },
  error: null,
  selectedRows: [],
  payload: {}
};

export const getRequest = (namespace) => (state = initialState, action) => {
  switch (action.type) {
    case `${namespace}/GET_REQUEST_REQUEST`: {
      return { ...state, data: { ...state.data, loading: true }, error: null };
    }
    case `${namespace}/GET_REQUEST_SUCCESS`: {
      return { ...state, data: { ...state.data, data: action?.data, page: action?.data?.page_number - 1, pageSize: state.data.pageSize, totalCount: action?.data?.row_count, loading: false } };
    }
    case `${namespace}/GET_REQUEST_FAILURE`: {
      return { ...state, data: { ...state.data, data: [], page: 0, pageSize: state.data.pageSize, totalCount: 0, loading: false }, error: action.error };
    }
    case `${namespace}/GET_REQUEST_SET_PAGE`: {
      return { ...state, data: { ...state.data, page: action?.data?.page } }
    }
    case `${namespace}/GET_REQUEST_RESET_PAGE`: {
      return { ...state, data: { ...state.data, page: 0 } }
    }
    case `${namespace}/GET_REQUEST_SET_PAGE_SIZE`: {
      return { ...state, data: { ...state.data, page: 0, pageSize: action?.data?.pageSize } }
    }
    case `${namespace}/GET_REQUEST_RESET_PAGE_SIZE`: {
      return { ...state, data: { ...state.data, pageSize: 10 } }
    }
    case `${namespace}/GET_REQUEST_SET_PAGE_AND_PAGE_SIZE`: {
      return { ...state, data: { ...state.data, page: action?.data?.page, pageSize: action?.data?.pageSize } }
    }
    case `${namespace}/GET_REQUEST_SET_PAYLOAD`: {
      return { ...state, data: { ...state.data, page: 0, pageSize: state.data.pageSize }, payload: action.data };
    }
    case `${namespace}/GET_REQUEST_RESET_PAYLOAD`: {
      return { ...state, data: { ...state.data, page: 0, pageSize: state.data.pageSize }, payload: { ...initialState.payload } };
    }
    case `${namespace}/GET_REQUEST_RESET`: {
      return initialState;
    }
    default:
      return state;
  }
};
