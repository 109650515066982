const initialState = {
    data: [],
    loading: false,
    success: false,
    error: false,
  };
  
  export const getDemographic = (namespace) => (state = initialState, action) => {
    switch (action.type) {
      case `${namespace}/GET_DEMOGRAPHİC_REQUEST_REQUEST`: {
        return { ...state, data: { ...state.data, loading: true }, error: null };
      }
      case `${namespace}/GET_DEMOGRAPHİC_REQUEST_SUCCESS`: {
        return { ...state, data: { ...state.data, data: action?.data, loading: false } };
      }
      case `${namespace}/GET_DEMOGRAPHİC_REQUEST_FAILURE`: {
        return { ...state, data: { ...state.data, data: [], loading: false }, error: action.error };
      }
      case `${namespace}/GET_DEMOGRAPHİC_REQUEST_SET_PAYLOAD`: {
        return { ...state, data: { ...state.data }, payload: action.data };
      }
      case `${namespace}/GET_DEMOGRAPHİC_REQUEST_RESET_PAYLOAD`: {
        return { ...state, data: { ...state.data }, payload: { ...initialState.payload } };
      }
      case `${namespace}/GET_DEMOGRAPHİC_REQUEST_RESET`: {
        return initialState;
      }
      default:
        return state;
    }
  };
  