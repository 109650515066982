import { combineReducers, createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import { reducer as notifications } from "react-notification-system-redux";
import { loginReducer, sidebarReducer, signupReducer, forgotPasswordReducer, forgotPasswordValidationLinkReducer, InvatationAcceptReducer, getRequest, getListRequest, deleteRequest, getByIdRequest, postRequest, queryPageRequest, emailVerifyReducer, newInstagramAccounts,getRequestWithMetrics, getAudience, putRequest, forgotPasswordValidIdHashReducer, postPageRequest, putRequestWithoutId, getDemographic, getByIdAndNameRequest, postImageRequest  } from "../../redux/reducers/index";




const reducer = combineReducers({
  sidebar: sidebarReducer,
  // Notification
  notifications,
  
  //Master
  timezoneList: getListRequest('helpers/timezones'),
  userGeneralList: getListRequest('users'),
  //Auth
  login: loginReducer,
  signup: signupReducer,
  forgotPassword: forgotPasswordReducer,
  forgotPasswordValidation: forgotPasswordValidationLinkReducer,
  invatationAccept: InvatationAcceptReducer,
  emailVerify: emailVerifyReducer,
  forgotPasswordValidationIdHash: forgotPasswordValidIdHashReducer,

  waitingVerifyEmail: postRequest("auth/verify/check"),
 

  //Role
  userPermissionsData: getListRequest('users/roles'),
  userRoles: getListRequest('users/user/14/role'),

  //User
  userList: queryPageRequest('workspace/list-users'),
  userDelete: deleteRequest('users'),
  userDataById: getByIdRequest('users/user'),
  userSave: postRequest('users'),
  userIdentifierUpdate: postRequest('workspace/update-identifiers'),

  userById: getByIdRequest('users'),

  //Social Media
  instagramList: getRequest('integration/social-media/instagram'),
  // instagramImpression: getRequest('integration/social-media/instagram'),
  instagramUrl: getRequest('integration/social-media/instagram/login'),
  instagramDetailChartData: getRequestWithMetrics('instagram/users'),
  DemographicData: getDemographic('instagram/users'),
  facebookPage: getRequest('facebook/pages'),

  ImpressionChartData: getRequestWithMetrics('instagram/users'),
  ReachChartData: getRequestWithMetrics('instagram/users'),


  // ImpressionsChartData: getRequestWithMetrics('integration/social-media/instagram'),
  // ReachChartData: getRequestWithMetrics('integration/social-media/instagram'),


  TopCity: getAudience("integration/social-media/instagram"),

  //newInstagramAccounts: postRequest('integration/social-media/instagram'),
  newInstagramAccountsData: newInstagramAccounts('instagram/users'),
 

  
  instagramDelete: deleteRequest('social-media-accounts'),


  InstagramAccountById: getByIdRequest('instagram/users'),
  audienceDemographic: getByIdRequest('social-media-accounts/instagram/:id/audience'),

  facebookAuth: getRequest('social-media-accounts'),

  //Store
  woocommerceData: getRequest("integration/ecommerce/stores"),
  UpdateWoocommerce: putRequest("integration/ecommerce/stores"),
  NewWoocommerce: postRequest('integration/ecommerce/stores'),
  WooCommerceById: getByIdRequest("integration/ecommerce/stores"),
  updateCurrency: postRequest('integration/ecommerce/stores'),
  deleteWoocommerce: deleteRequest('integration/ecommerce/stores'),

  //Support
  supportList: getRequest('users/support'),

  //Analytics
  analyticsInstagramQueryPage: queryPageRequest('instagram/medias'),
  analyticsFavInstagramQueryPage: queryPageRequest('favorites/instagram'),

  analyticsFilterList: getListRequest('analytics/instagram/filters'),
  savedFilter: getByIdRequest('analytics/instagram/filters'),
  // dataStatus: getRequest('analytics/instagram/helpers/instagram-users'),
  instagramUsers: getRequest('instagram/users'),

  saveThisFilter: postRequest("analytics/instagram/filters"),
  deletedFilter: deleteRequest("analytics/instagram/filters"),

  addFav: getRequest("analytics/favorite"),
  removeFav: deleteRequest("analytics/favorite"),
  favCollections: getListRequest(`favorites/categories`),
  newFavCollection: postRequest(`favorites/categories`),
  editFavCollection: putRequest(`favorites/categories`),
  deleteFavCollection: deleteRequest(`favorites/categories`),
  favoritesAdd: getListRequest(`favorites/actions/add`),
  favoritesRemove: getListRequest(`favorites/actions/remove`),

  //Analytics Detail
  analyticsInstagramDetailPage: getByIdRequest("instagram/post"),
  analyticsInstagramDetailGeneralInfo: getByIdRequest("instagram/medias"),
  analyticsInstagramInsight: getByIdAndNameRequest("instagram/medias"),

  //Product
  ProductQueryPage: queryPageRequest('ecommerce/products'),
  productCollectionsList: getListRequest('integration/ecommerce/categories'),
  productStoreList: getListRequest('helpers/stores'),
  productUserList: getListRequest('helpers/users'),

  //Order
  OrderQueryPage: queryPageRequest('ecommerce/orders'),

  //Dashboard
  // OverviewStatsData: postRequest("dashboard/overview/sales"),
  OverviewStatsData: getRequest("ecommerce/stats/sales"),

  // OverviewTopProductData: postRequest("dashboard/overview/top-products"),
  OverviewTopProductData: postPageRequest("dashboard/overview/top-products"),
  accountDistirubitionData: postRequest("dashboard/overview/account-distribution"),

  //Helpers
  // accountList: getListRequest("helpers/instagram-accounts"),
  currencyList: getListRequest("helpers/currencies"),
  avaibleCurrencyList: getListRequest("ecommerce/get-available-currencies"),
  // updateMeta: putRequestWithoutId("me/meta"),

  //Settings
  updateMetaName: putRequestWithoutId("me/name"),
  updatePassword: putRequestWithoutId("me/password"),
  updateEmail: putRequestWithoutId("me/email"),
  updateMetas: putRequestWithoutId("me/metas/meta"),
  updateImage: postImageRequest("me/profile-picture"),
  updateImageType: postRequest("me/profile-picture"),


});

const store = createStore(reducer, applyMiddleware(thunk));

export default store;
