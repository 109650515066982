const initialState = {
    loading: false,
    success: false,
    error: false,
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case 'INVATATION_REQUEST': {
        return {...initialState, loading: true};
      }
      case 'INVATATION_SUCCESS': {
        return {...state, success: true, loading: false, error: false};
      }
      case 'INVATATION_FAILURE': {
        return {...state, error: false, loading: false};
      }
      default:
        return state;
    }
  }