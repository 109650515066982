
function getUserJSON() {
    const user = localStorage.getItem('user');
    return user && JSON.parse(user);
  }
  
  export function getUser() {
    return getUserJSON();
  }
  
  export function setUser(res) {
    localStorage.setItem('userInfo', JSON.stringify({...getUser(), ...res}))
  }
  
  export function createUser(res) {
    localStorage.setItem('user', JSON.stringify(res));
  }
  export function createUserInfo(res) {
    localStorage.setItem('userInfo', JSON.stringify(res));
  }
  export function removeUserInfo() {
    localStorage.removeItem('userInfo');
  }  
  export function removeUser() {
    localStorage.removeItem('user');
  }
  export function getUserAccessModules() {
    return getUserJSON() && getUserJSON().accessModules && getUserJSON().accessModules;
  }
  
  export function userAccessModuleControl(value) {
    return getUserAccessModules() && getUserAccessModules().includes(value);
  }


  export function getUserId() {
    return  getUser()?.id
  }
  
  export function setUserId(id){
    localStorage.setItem('user', JSON.stringify({...getUser(), id: id }));
  }

  export function getUserName(){
    return getUser() && getUser()?.name
  }
  
  export function getUserEmail(){
    return getUser() && getUser()?.email
  }
  
  export function setUserName(name) {
    localStorage.setItem('user', JSON.stringify({...getUser(), name: name }));
  }

  
   export function getUserRole(){
    return getUser() && getUser().role?.name
  }
  
  export function setUserRole(role) {
    localStorage.setItem('user', JSON.stringify({...getUser(), role: role }));
  }

  export function getUserPermissions() {
    const user = getUser();

    if (!user || !user.role) return [];
  
    // const permissions = user.role.reduce((allPermissions, role) => {
    //   if (role.permissions) {
    //     allPermissions.push(role.permissions);
    //   }
    //   return allPermissions;
    // }, []);
    const permissions = user.role.permissions;
  
    return permissions;
  }
  
  export function getUserTeamId() {
    return  getUser()?.team?.id
  }
  
  