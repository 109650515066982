import React, { Component, Suspense } from "react";
import { Provider } from "react-redux";
import store from "../../pages/App/store";

import { Router, useLocation } from "react-router-dom";
import { history } from "../../utils/helpers/history";
import Routers from "../../pages/App/Router";

// Notification
import NotificationContainer from "../../components/Shared/Notification";

// Util functions

// Import Css
import "../../assets/css/materialdesignicons.min.css";
import "../../assets/css/colors/default.css";
import "../../Apps.scss";

import ThemeProvider from "./Contexts/ThemeProvider";
import { LanguageProvider } from './Contexts/LanguageContext';


import { useEffect, useState } from "react";

//Sentry
import * as Sentry from "@sentry/react"

Sentry.init({
  dsn: "https://de8440c66e4f8250e316b01c80d0bb77@o4505481654239232.ingest.sentry.io/4505702582910976",
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
 });

export default function App() {
  const [loading, setLoading] = useState();

  const location = useLocation();


  useEffect(() =>
    window.addEventListener("load", () => {
      isDashboard();
      setLoading(true);
      // setTimeout(() => setLoading(true), 500);
    })
  );
  useEffect(() => {
    isDashboard();
  })
  const isDashboard = () => {
    if (location.pathname === "/") {
      history.push("/dashboard");
    }
  };

  const Loader = () => {
    return (
      <div id={`${loading ? "" : "preloader"}`}>
        <div id="status">
          <div className="loader"></div>
        </div>
      </div>
    );
  };
  return (
    <React.Fragment>
      <Provider store={store}>
        <Router basename="/" history={history}>
          <ThemeProvider>
          <LanguageProvider>
            {!loading && (
              <div id="preloader" >
                <div id="status">
                  <div className="loader"></div>
                </div>
              </div>
            )}
            <Suspense fallback={Loader}>
              <Routers />
            </Suspense>
           </LanguageProvider>
          </ThemeProvider>
        </Router>
        <NotificationContainer props />
      </Provider>
    </React.Fragment>
  );
}
