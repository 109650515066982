/*
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
*/

import { getAccessToken } from "../helpers/tokenHelper";
import {history} from "../helpers/history"

const onRequest = (config) => {
  config.headers["Authorization"] = getAccessToken ? `Bearer ${getAccessToken()}` : null;

  return config;
};

const onRequestError = (error) => {
  return Promise.reject(error);
};

const onResponse = (response) => {
  if (response.status === 205) {
    window.location.href = '/auth/logout';
  }
  if (response.status === 401) {
    return window.location.href = "/login";
  }
  if (response.status === 503) {
    return window.location.href = "/error/503";
  }
  if (response.status === 404) {
    return window.location.href = "/error/404";
  }
  return response;
};

const onResponseError = async (error) => {
  if (!error.response) {
    return Promise.reject(error);
  }

  const { status, data } = error.response;

  if (status === 401 && data.message === "Unauthenticated.") {
    history.push("/login");
  } else if (status === 500) {
    history.push("/error");
  } else if (status === 503) {
    history.push("/error/503");
  } else if (status === 404) {
    history.push("/error/404");
  } else if (status === 205 && data?.error) {
    window.location.href = '/login';
  }

  return Promise.reject(error);
};


export const setupInterceptorsTo = (
  axiosInstance
) => {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
};