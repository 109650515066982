import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Notifications from 'react-notification-system-redux';

class Notification extends PureComponent {
  render() {
    const { notifications } = this.props;

    return (
      <Notifications
        notifications={notifications}
      // style={style}
      />
    );
  }
}

Notification.propTypes = {
  notifications: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]),
};

export default connect(
  state => ({ notifications: state.notifications })
)(Notification);
