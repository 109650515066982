import React from "react";
import { Route, Redirect } from "react-router-dom";
import { getUser } from "../../../../utils/helpers/userHelper";
import { getAccessToken } from "../../../../utils/helpers/tokenHelper";

export const PrivateRoute = ({ component: Component, extraProps, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (!getUser() && !getAccessToken()) {
        return (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        );
      }
      if (extraProps) {
        props = Object.assign({}, props, extraProps);
      }

      return <Component {...props} />;
    }}
  />
);


// const PrivateRoute = ({ component: Component, ...rest }) => {
//   const user = JSON.parse(localStorage.getItem('user')); // Kullanıcının bilgilerini alın
//   const userRole = user ? user.role : null; // Kullanıcının rolünü alın

//   const roleRoutes = {
//     admin: ['*', '/dashboard', '/workspace', '/social-media'], // Admin tüm sayfalara erişebilir
//     user: ['/dashboard', '/profile'], // Normal kullanıcı sadece bu sayfalara erişebilir
//     // Diğer rolleri de buraya ekleyebilirsiniz
//   };

//   const allowedRoutes = roleRoutes[userRole] || [];

//   return (
//     <Route
//       {...rest}
//       render={(props) =>
//         allowedRoutes.includes('*') || allowedRoutes.includes(rest.path) ? (
//           <Component {...props} />
//         ) : (
//           <Redirect to="/dashboard" />
//         )
//       }
//     />
//   );
// };

// export default PrivateRoute;
