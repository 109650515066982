import { get } from '../../../utils/api/index';
import { addNotification } from '../notification/notificationActions';


function request(namespace) {
  return {
    type: `${namespace}/GET_LIST_REQUEST_REQUEST`,
  };
}

function success(namespace, data) {
  return {
    type: `${namespace}/GET_LIST_REQUEST_SUCCESS`,
    data,
  };
}

function failure(namespace, error) {
  return {
    type: `${namespace}/GET_LIST_REQUEST_FAILURE`,
    error,
  };
}

// export const reset = (namespace) => async (dispatch) => {
//   console.log("namespace", namespace);
//   try {
//     return {
//       type: `${namespace}/GET_LIST_REQUEST_RESET`,
//     };
//   } catch (error) {
//     throw error;
//   }
// }

export function reset (namespace){
  return {
    type: `${namespace}/GET_LIST_REQUEST_RESET`,
  };
}

export function setFilterValues(namespace, data) {
  return {
    type: `${namespace}/GET_LIST_REQUEST_SET_FILTER_VALUES`,
    data,
  };
}

export function resetFilterValues(namespace) {
  return {
    type: `${namespace}/GET_LIST_REQUEST_RESET_FILTER_VALUES`,
  };
}

function toQueryString(obj) {
  return obj ? Object.keys(obj).sort().map(function (key) {
    var val = obj[key];

    if (Array.isArray(val)) {
      return val.sort().map(function (val2) {
        return encodeURIComponent(key) + '=' + encodeURIComponent(val2 instanceof Date ? Date.parse(val2) : val2);
      }).join('&');
    }

    return encodeURIComponent(key) + '=' + encodeURIComponent(val instanceof Date ? Date.parse(val) : val);
  }).join('&') : '';
}


export const getListRequest = (namespace, { storeVariable = undefined }={}) => async (dispatch, getState) => {

  let filter = ''
  
  if (storeVariable && getState()[storeVariable] && getState()[storeVariable].filterValues) {
    filter = '?' + toQueryString(getState()[storeVariable].filterValues);
  }

  try {
    dispatch(request(namespace));
    const res = await get(`${process.env.REACT_APP_API_URL}/${namespace}${filter}`);

    if (res.data.errorCode) {
      dispatch(addNotification({messageText: res.data.errorCode, level: 'error'}));
    } else {
      dispatch(success(namespace, res.data));
    }
  } catch (error) {

    dispatch(failure(namespace, error));
    if (error.response !== undefined && error.response.status !== 500) {
      dispatch(addNotification({messageText: error.response.status, level: 'error'}));
    } else {
      dispatch(addNotification({messageText: '500', level: 'error'}));
    }
    throw error;
  }
}

