const initialState = {
    data: [],
    loading: false,
    success: false,
    error: false,
  };
  
  export const putRequestWithoutId = (namespace) => (state = initialState, action) => {
    switch (action.type) {
      case `${namespace}/PUT_REQUEST_REQUEST`: {
        return { ...initialState, loading: true };
      }
      case `${namespace}/PUT_REQUEST_SUCCESS`: {
        return { ...state, data: action.data, loading: false, success: true };
      }
      case `${namespace}/PUT_REQUEST_FAILURE`: {
        return { ...state, data: action.error, loading: false, success: false, error: true };
      }
      case `${namespace}/PUT_REQUEST_RESET`: {
        return { ...initialState }; 
      }
      default:
        return state;
    }
  };
  