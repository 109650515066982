import { get } from '../../../utils/api/index';
import { addNotification } from '../notification/notificationActions';

function request(namespace) {
  return {
    type: `${namespace}/GET_REQUEST_REQUEST`,
  };
}

function success(namespace, data) {
  return {
    type: `${namespace}/GET_REQUEST_SUCCESS`,
    data,
  };
}

function failure(namespace, error) {
  return {
    type: `${namespace}/GET_REQUEST_FAILURE`,
    error,
  };
}

function reset(namespace) {
  return {
    type: `${namespace}/GET_REQUEST_RESET`,
  };
}

export function setPage(namespace, data) {
  return {
    type: `${namespace}/GET_REQUEST_SET_PAGE`,
    data,
  };
}

export function resetPage(namespace) {
  return {
    type: `${namespace}/GET_REQUEST_RESET_PAGE`,
  };
}

export function setPageSize(namespace, data) {
  return {
    type: `${namespace}/GET_REQUEST_SET_PAGE_SIZE`,
    data,
  };
}

export function resetPageSize(namespace, data) {
  return {
    type: `${namespace}/GET_REQUEST_RESET_PAGE_SIZE`,
  };
}

export function setPageandPageSize(namespace, data) {
  return {
    type: `${namespace}/GET_REQUEST_SET_PAGE_AND_PAGE_SIZE`,
    data,
  };
}

export function setPayload(namespace, data) {
  console.log("setPayload data", data);
  return {
    type: `${namespace}/GET_REQUEST_SET_PAYLOAD`,
    data,
  };
}

export function resetPayload(namespace) {
  return {
    type: `${namespace}/GET_REQUEST_RESET_PAYLOAD`,
  };
}


function toQueryString(obj) {
  return obj ? Object.keys(obj).sort().map(function (key) {
      var val = obj[key];
    if(val == 'undefined') {
    }else {
      if (Array.isArray(val)) {
        return val.sort().map(function (val2) {
            return encodeURIComponent(key) + '=' + encodeURIComponent(val2 instanceof Date ? Date.parse(val2) : val2);
        }).join('&');
      }
      return encodeURIComponent(key) + '=' + encodeURIComponent(val instanceof Date ? Date.parse(val) : val);
    }}).join('&') : '';
}

const toArrayQueryString = (values) => {
  const queryString = Object.keys(values)
    .flatMap(key => {
      if(Array.isArray(values[key])){
        return values[key].map((value, index) => `${encodeURIComponent(key + '['+index+']')}=${encodeURIComponent(value)}`);
      }
      return `${encodeURIComponent(key)}=${encodeURIComponent(values[key])}`;
    })
    .join('&');

  return queryString ? `?${queryString}` : "";
};


export const getRequest = (namespace, { storeVariable = undefined, processing = false } = {}) => async (dispatch, getState) => {
  let filter = '';

  const payload = getState()[storeVariable]?.payload;

  if (payload && Object.keys(payload).length !== 0){
    filter = toArrayQueryString(payload);
  }

  try {
    dispatch(request(namespace));
    const res = await get(`${process.env.REACT_APP_API_URL}/${namespace}${filter}`, payload);
    if (res.data.errorCode) {
      dispatch(addNotification({messageText: res.data.errorCode, level: 'error'}));
    } else {
      dispatch(success(namespace, res.data));
    }
  } catch (error) {
    dispatch(failure(namespace, error));
    if (error.response !== undefined) {
      dispatch(addNotification({messageText: error.response.status, level: 'error'}));
    } else {
      dispatch(addNotification({messageText: '500', level: 'error'}));
    }
    throw error;
  }
}


export const resetList = (namespace) => async (dispatch) => {
  try {
    dispatch(reset(namespace));
  } catch (error) {
    throw error;
  }
}
